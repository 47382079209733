<template>
  <vueEditor
    v-if="!loadingImage"
    v-model="text"
    :placeholder="placeholder"
    :editor-toolbar="customToolbar"
    useCustomImageHandler
    @image-added="onUploadImage"
    @text-change="onTextChange"
    @focus="onEditorFocus"
    @blur="onEditorBlur"
    @selection-change="onSelectionChange"
  ></vueEditor>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "HayaRichTextField",
  props: {
    id: String,
    module: String,
    value: String,
    subFolder: String,
    placeholder: String,
    formField: String,
  },
  components: { VueEditor },
  data() {
    return {
      text: "",
      loadingImage: false,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean", "image"],
      ],
    };
  },
  mounted() {
    this.text = this.value;
  },
  methods: {
    onTextChange() {
      if (this.text.includes("data:image/png")) {
        this.loadingImage = true;

        let imageBase64 = this.text
          .replace('<p><img src="', "")
          .replace('"></p>', "");

        this.uploadImageToS3(imageBase64).then(({ data }) => {
          console.log(data);
          this.$http.post("/file/create", {
            user_id: this.$store.state.userData.id,
            customer_id: 0,
            module_id: this.id,
            module: this.module,
            name: data.fileName,
            extension: data.fileType,
            url: data.url,
            //size: file.size,
          });

          this.$emit(
            "textChange",
            this.formField,
            `<p><img src="${data.url}" alt="${data.fileName}" /></p>`
          );

          setTimeout(() => {
            this.loadingImage = false;
            this.text = `<p><img src="${data.url}" alt="${data.fileName}" /></p>`;
          }, 1000);
        });
      } else {
        this.$emit("textChange", this.formField, this.text);
      }
    },
    uploadImageToS3(file) {
      let theSubFolder = "";
      if (this.subFolder) theSubFolder = `&sub-folder=${this.subFolder}`;

      const data = this.$http.post(
        `/media/upload-base64-to-s3?module=${this.module}&id=${this.id}${theSubFolder}`,
        {
          file,
        }
      );

      return data;
    },
    onUploadImage(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("file", file);

      let theSubFolder = "";
      if (this.subFolder) theSubFolder = `&sub-folder=${this.subFolder}`;

      this.$http
        .post(
          `/media/upload-to-s3?module=${this.module}&id=${this.id}${theSubFolder}`,
          formData
        )
        .then(({ data }) => {
          this.$http.post("/file/create", {
            user_id: this.$store.state.userData.id,
            customer_id: 0,
            module_id: this.id,
            module: this.module,
            name: file.name,
            extension: file.type,
            size: file.size,
            url: data.url,
          });

          Editor.insertEmbed(cursorLocation, "image", data.url);
          resetUploader();
        });
    },
  },
};
</script>
