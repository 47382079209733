<template>
  <div>
    <div class="schedules">
      <div v-for="(schedule, index) in scheduleRows" :key="index">
        <div class="collapse-card" v-if="openCollapse == schedule.id">
          <aRow type="flex" :gutter="20">
            <aCol :span="22">
              <aFormItem class="travel-input-outer">
                <label class="filled"> Título </label>
                <aInput
                  class="travel-input"
                  placeholder="Escreva"
                  v-decorator="[
                    `package_schedule_${schedule.id}_title`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </aInput>
              </aFormItem>

              <div>
                <label class="label"> Descrição </label>
                <HayaRichTextField
                  module="products"
                  :subFolder="`text-editor/schedule/${schedule.id}`"
                  :id="tempProduct.id"
                  :value="
                    tempProduct[`package_schedule_${schedule.id}_description`]
                  "
                  :placeholder="'Digite o texto aqui'"
                  :formField="`package_schedule_${schedule.id}_description`"
                  @textChange="onChangeText"
                />
              </div>
            </aCol>

            <aCol class="a-right" :span="2">
              <div class="mb-10">
                <a-button
                  @click="onClickOpenCollapse(schedule.id)"
                  shape="circle"
                  icon="down"
                />
              </div>
              <div>
                <a-button
                  v-if="scheduleRows.length == schedule.id"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  @click="removeScheduleRow(schedule.id)"
                />
              </div>
            </aCol>
          </aRow>
        </div>
        <div
          class="collapse-card c-pointer"
          @click="onClickOpenCollapse(schedule.id)"
          v-else
        >
          <aRow type="flex" :gutter="20" align="middle">
            <aCol :span="21">
              <div class="title">
                {{
                  tempProduct[`package_schedule_${schedule.id}_title`] ||
                  "Escreva um título..."
                }}
              </div>
            </aCol>
            <aCol class="a-right" :span="3">
              <div>
                <a-button
                  class="mr-5"
                  v-if="scheduleRows.length == schedule.id"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  @click="removeScheduleRow(schedule.id)"
                />
                <a-button
                  @click="onClickOpenCollapse(schedule.id)"
                  shape="circle"
                  icon="up"
                />
              </div>
            </aCol>
          </aRow>
        </div>
      </div>

      <div class="no-data" v-if="scheduleRows.length === 0">
        Nenhuma programação cadastrada.
      </div>

      <div class="mt-20 mb-40 a-center">
        <a-button
          type="primary"
          @click="addScheduleRow(scheduleRows.length)"
          class="add-button f12"
        >
          Adicionar programação
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
// components
import HayaRichTextField from "@/components/general/fields/HayaRichTextField.vue";

export default {
  components: { HayaRichTextField },
  name: "PackageProgrammingCollapse",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  data() {
    return {
      openCollapse: -1,
      scheduleRows: [],
    };
  },
  mounted() {
    if (this.tempProduct?.package_schedule_rows)
      this.scheduleRows = JSON.parse(this.tempProduct.package_schedule_rows);

    this.productForm.setFieldsValue(this.tempProduct);
  },
  methods: {
    onChangeText(field, text) {
      this.tempProduct[field] = text;
    },
    addScheduleRow(id) {
      let newId = id + 1;
      this.scheduleRows.push({
        id: newId,
      });

      this.openCollapse = newId;

      this.tempProduct["package_schedule_rows"] = JSON.stringify(
        this.scheduleRows
      );
    },
    removeScheduleRow(id) {
      for (let i in this.scheduleRows) {
        if (this.scheduleRows[i].id == id) {
          this.scheduleRows.splice(i, 1);
          break;
        }
      }
      this.tempProduct["package_schedule_rows"] = JSON.stringify(
        this.scheduleRows
      );
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;

        setTimeout(() => {
          this.productForm.setFieldsValue({
            [`package_schedule_${id}_title`]:
              this.tempProduct[`package_schedule_${id}_title`],
          });
        }, 5);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  z-index: 4
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  width: 100%
  margin-bottom: 10px
  display: block

.no-data
  text-align: center
  padding: 40px
  font-size: 12px
  color: #aaa

.collapse-card
  clear: both
  padding: 20px
  background: #fff
  border: 1px solid #ccc
  margin-bottom: 20px
  border-radius: 6px
  .title
    color: #333
    font-size: 12px
    font-weight: 500
</style>
