var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"schedules"},[_vm._l((_vm.scheduleRows),function(schedule,index){return _c('div',{key:index},[(_vm.openCollapse == schedule.id)?_c('div',{staticClass:"collapse-card"},[_c('aRow',{attrs:{"type":"flex","gutter":20}},[_c('aCol',{attrs:{"span":22}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v(" Título ")]),_vm._v(" "),_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `package_schedule_${schedule.id}_title`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `package_schedule_${schedule.id}_title`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1),_vm._v(" "),_c('div',[_c('label',{staticClass:"label"},[_vm._v(" Descrição ")]),_vm._v(" "),_c('HayaRichTextField',{attrs:{"module":"products","subFolder":`text-editor/schedule/${schedule.id}`,"id":_vm.tempProduct.id,"value":_vm.tempProduct[`package_schedule_${schedule.id}_description`],"placeholder":'Digite o texto aqui',"formField":`package_schedule_${schedule.id}_description`},on:{"textChange":_vm.onChangeText}})],1)],1),_vm._v(" "),_c('aCol',{staticClass:"a-right",attrs:{"span":2}},[_c('div',{staticClass:"mb-10"},[_c('a-button',{attrs:{"shape":"circle","icon":"down"},on:{"click":function($event){return _vm.onClickOpenCollapse(schedule.id)}}})],1),_vm._v(" "),_c('div',[(_vm.scheduleRows.length == schedule.id)?_c('a-button',{attrs:{"type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeScheduleRow(schedule.id)}}}):_vm._e()],1)])],1)],1):_c('div',{staticClass:"collapse-card c-pointer",on:{"click":function($event){return _vm.onClickOpenCollapse(schedule.id)}}},[_c('aRow',{attrs:{"type":"flex","gutter":20,"align":"middle"}},[_c('aCol',{attrs:{"span":21}},[_c('div',{staticClass:"title"},[_vm._v("\n              "+_vm._s(_vm.tempProduct[`package_schedule_${schedule.id}_title`] ||
                "Escreva um título...")+"\n            ")])]),_vm._v(" "),_c('aCol',{staticClass:"a-right",attrs:{"span":3}},[_c('div',[(_vm.scheduleRows.length == schedule.id)?_c('a-button',{staticClass:"mr-5",attrs:{"type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeScheduleRow(schedule.id)}}}):_vm._e(),_vm._v(" "),_c('a-button',{attrs:{"shape":"circle","icon":"up"},on:{"click":function($event){return _vm.onClickOpenCollapse(schedule.id)}}})],1)])],1)],1)])}),_vm._v(" "),(_vm.scheduleRows.length === 0)?_c('div',{staticClass:"no-data"},[_vm._v("\n      Nenhuma programação cadastrada.\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-20 mb-40 a-center"},[_c('a-button',{staticClass:"add-button f12",attrs:{"type":"primary"},on:{"click":function($event){return _vm.addScheduleRow(_vm.scheduleRows.length)}}},[_vm._v("\n        Adicionar programação\n      ")])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }